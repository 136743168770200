<template>
    <div class="grid crud-demo">
        <Loader v-model="loading"/>
        <div class="col-12">
            <div class="card">
                <Panel header="Administrar Pedidos">
                    <Dialog v-model:visible="documentDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
                      <OrderUI :id="id_document" :isModal="true" />
                    </Dialog>
                    <BasicFormToolbar @list="list" :actions="['list']" />
                    <div class="grid">
                        <div class="col-12">
                            <Fieldset legend="Filtros">
                                <Helper v-model="salesmanHelper" header="Buscar Vendedor" :headers="salesmanHeaders" :rows="salesmen" @selected="selectSalesMan" />
                                <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customerHeaders" :rows="customers" @selected="selectCustomer" />
                                <div class="p-fluid formgrid grid">
                                    <FormCalendar wrapperClass="field col-4" label="Inicio" v-model="entity.initial_date"/>
                                    <FormCalendar wrapperClass="field col-4" label="Fin" v-model="entity.final_date"/>
                                </div>
                                <div class="p-fluid formgrid grid">
                                    <FormInputText wrapperClass="field col-2" label="Vendedor"  @search="(salesmanHelper.visible=true)" :search="true" v-model="entity.username"/>
                                    <FormInputText wrapperClass="field col-3" label="Nombre Vendedor" v-model="entity.salesman_name"/>
                                    <FormInputText wrapperClass="field col-2" label="Cliente" @search="(customerHelper.visible=true)" :search="true" v-model="entity.id_customer"/>
                                    <FormInputText wrapperClass="field col-3" label="Nombre Cliente" v-model="entity.customer_name"/>
                                    <FormDropdown :wrapperClass="'field col-2 md:col-2 xl:col-2'" :options="name_status" v-model="entity.name_status" :optionLabel="'text'" :optionValue="'id'" label="Estatus" />
                                </div>
                            </Fieldset> <br>
                            <Fieldset legend="Resultados">
                                <BasicDatatable
                                :headers="headers"
                                :rows="entities"
                                :selectionMode="'single'"
                                :rowaction="true"
                                :rowviewDocument="true"
                                :rowdelete="true"
                                @deleted="cancelar"
                                @viewDocument="viewDocument"
                                />
                            </Fieldset>
                           
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import Helper from '../../../components/general/HelperDialog.vue';
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import moment from 'moment';
import {Order} from '../../../models/comercial/Order';
import {Customer} from '../../../models/comercial/Customer';
import {SalesMan} from '../../../models/comercial/SalesMan';
import FormDropdown from "../../../components/general/FormDropdown.vue";
import OrderUI from "./Order.vue"
import { Quotation } from "../../../models/crm/Quotation";

import {
    HeaderGrid,
    ErrorToast,
    Toast,
    fillObject,
  } from "../../../utilities/General";

export default{
    mixins: [Session],
    data(){
        return{
          id_document: null,
          documentDialog: null,
            loading: false,
            entity: new Order(this.session),
            quotation: new Quotation(),
            entities:[],
            salesmen:[],
            customers:[],
            name_status: [
                { id: '1', text: 'Registrado' },
                { id: '2', text: 'Requerido' },
                { id: '3', text: 'Afectado' },
                { id: '9', text: 'Cancelado' }
            ],
            salesmanHelper:{
                visible: false
            },
            customerHelper:{
                visible: false
            },
            salesmanHeaders:[
                new HeaderGrid('Nombre','name'),
                new HeaderGrid('Apellido','last_name'),
                new HeaderGrid('Usuario','username')
            ],
            customerHeaders:[
                new HeaderGrid('Nombre','name'),
                new HeaderGrid('RFC','rfc')
            ],
            headers:[
                new HeaderGrid('Estatus','name_status',{type: 'component', component:'Tag'}),
                new HeaderGrid('Facturado',''),
                new HeaderGrid('Pedido','id'),
                new HeaderGrid('Fecha','order_date',{type: 'date'}),
                new HeaderGrid('Cliente','customer_id'),
                new HeaderGrid('Nombre del cliente','customer_name'),
                new HeaderGrid('Vendedor','created_by', {imgUser: true}),
                new HeaderGrid('Tipo Moneda','currency'),
                new HeaderGrid('Subtotal','subtotal',{type: 'currency',function:'SUM'}),
                new HeaderGrid('Total','total',{type: 'currency',function:'SUM'}),
            ]
        }
    },
    components:{Loader,BasicFormToolbar,FormCalendar,FormInputText,Helper,BasicDatatable,FormDropdown,OrderUI},
    created() {
      this.entity = new Order(this.session);
    },
    async mounted() {
      await this.refresh();
    },
    methods: {
      openNew() {
        this.entity = {
          branch: null,
          company: null,
          initial_date: new Date(),
          final_date: new Date(),
          name_status: null,
          salesman: null
        }
      },
      async list() {
        this.loading = true;
        try {
          this.entity.branch = this.session.branch;
          this.entity.company = this.session.company;
          this.entities = await new Order().data({
            branch: this.session.branch,
            company: this.session.company,
            customer_id: this.entity.id_customer,
            initial_date: moment(this.entity.initial_date).format(),
            final_date: moment(this.entity.final_date).format(),
            status: this.entity.name_status,
            salesman: this.entity.username
          });
          console.log(this.entities);
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },
      async cancelar(entity) {
      this.loading = true;
      this.entity = fillObject(this.entity, entity);
      try {
        if (this.entity.status == 9) 
        throw "El pedido debe estar en estatus 'REGISTRADA' para realizar esta accion"
      this.quotation.id = this.entity.id_crm_quotation;
      this.quotation.status = 2;
      this.entity.status = 9;
      await this.entity.cancelar();
      this.entities = new Order(this.session);
        this.entities.find(x => x.id == this.entity.id).status = 9;
        this.$toast.add(
          new Toast({
            summary: "Pedido",
            detail: "Pedido cancelado con exito",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
      },
      async refresh() {
        this.loading = true;
        try{
          this.customers = await new Customer(this.session).all();
          this.salesmen = await new SalesMan(this.session).all();
        }catch(error){
          this.$toast.add(new ErrorToast(error));
        }finally{
          this.loading = false;
        }
      },
      async selectCustomer(payload){
        console.log(payload);
        this.entity.id_customer = payload.id;
        this.entity.customer_name = payload.name;
      },
      async selectSalesMan(payload){
        this.entity.username = payload.username;
        this.entity.salesman_name = payload.name;
      },
      viewDocument(payload){
        this.id_document = payload.id;
        this.documentDialog = true;
      },
    },
};
</script>

<style>
</style>